.Adventages {
  @apply flex justify-center items-center flex-col;
  .AdventagesTitle {
    @apply uppercase;
    font-family: "Alumni Sans", sans-serif;
    font-size: 80px;
    font-weight: 500;
    letter-spacing: 4px;
    padding-top: 80px;
  }
  .AdventagesDescription {
    text-align: center;
  }
  .AdventagesCards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 80px;
    padding-top: 40px;
    .AdventagesItem {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      height: 450px;
      width: 340px;
      padding: 30px 10px;
      position: relative;
      background-color: white;
      margin: 15px;
      ::before {
        content: "";
        width: 350px;
        height: 340px;
        background-color: #c5a47e;
        z-index: -1;
        position: absolute;
        left: 0;
        top: -20px;
      }
      ::after {
        content: "";
        background-color: #2b2b2b;
        z-index: -1;
        position: absolute;
        width: 100px;
        height: 100px;
        bottom: -15px;
        left: -15px;
      }

      .AdventagesItemTitle {
        @apply uppercase;
        font-family: "Alumni Sans", sans-serif;
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 4px;
        text-align: center;
      }
      .AdventagesItemList {
        padding-left: 10px;
        padding-top: 40px;
        .AdventagesItemListData {
          @apply flex gap-2 items-center;
          padding: 15px 0px;

          .AdventagesItemListDot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #c5a47e;
          }
          p {
            width: 250px;
          }
        }
      }
    }
  }
}

@media (max-width: 1740px) {
  .Adventages {
    .AdventagesTitle {
      font-size: 70px;
    }
  }
}
@media (max-width: 1240px) {
  .Adventages {
    .AdventagesTitle {
      font-size: 65px;
    }
  }
}
@media (max-width: 700px) {
  .Adventages {
    .AdventagesTitle {
      font-size: 55px;
    }
  }
}
@media (max-width: 600px) {
  .Adventages {
    .AdventagesTitle {
      font-size: 45px;
    }
  }
}
