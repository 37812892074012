.HomeMain {
  width: 100vw;
  height: calc(100vh - 90px);
  .HomeMainBackground {
    @apply flex justify-start items-end;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #2b2b2b;
      background-size: cover;
      opacity: 0.8;
      z-index: 1;
    }
  }
  .HomeMainData {
    @apply flex flex-col justify-end;
    position: relative;
    z-index: 100;
    color: white;
    padding-left: 128px;
    padding-bottom: 200px;
    height: calc(100vh - 90px);
    .HomeMainTitle {
      @apply uppercase;
      font-size: 95px;
      font-family: Alumni Sans;
      letter-spacing: 0.06em;
      font-weight: 600;
      padding: 0;
    }
    .HomeMainButton {
      font-family: "Open Sans", sans-serif;
      width: 200px;
      height: 60px;
      border: 2px solid white;
      margin-top: 30px;
      transition: 0.3s all;
      border-radius: 2px;

      &:hover {
        scale: 1.05;
      }
      &:active {
        transform: translateY(10px);
      }
    }
  }
}

@media (max-width: 1240px) {
  .HomeMain {
    .HomeMainData {
      .HomeMainTitle {
        font-size: 85px;
      }
    }
  }
}
@media (max-width: 1000px) {
  .HomeMain {
    .HomeMainData {
      padding-left: 64px !important;
    }
  }
}
@media (max-width: 700px) {
  .HomeMain {
    .HomeMainData {
      padding-bottom: 150px !important;
    }
  }
}
@media (max-width: 600px) {
  .HomeMain {
    .HomeMainData {
      padding-left: 36px !important;
      padding-bottom: 100px !important;
      .HomeMainTitle {
        font-size: 65px !important;
      }
    }
  }
}
@media (max-width: 400px) {
  .HomeMain {
    .HomeMainData {
      padding-left: 16px !important;
      .HomeMainTitle {
        font-size: 55px !important;
      }
    }
  }
}
