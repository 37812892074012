.HomeServices {
  @apply flex flex-col;
  gap: 50px;
  background-size: cover;
  width: 100vw;
  min-height: 1050px;
  padding-top: 100px;
  .AboutTechnique,
  .AboutWork {
    @apply flex justify-center items-center;
    gap: 100px;
    .AboutTechniqueImg,
    .AboutWorkImg {
      @apply flex justify-start items-end;
      width: 650px;
      height: 400px;
      background-size: cover;
      .AboutTechniqueImgText {
        width: auto;
        min-width: 250px;
        height: 90px;
        background-color: white;
        .AboutTechniqueImgTitle {
          font-family: "Open Sans";
          letter-spacing: -1.5px;
          text-transform: uppercase;
          font-size: 18px;
          padding: 10px 0px;
          padding-right: 10px;
          margin: 0;
        }
        .AboutTechniqueImgDescription {
          font-family: "Open Sans";
          letter-spacing: -1.5px;
          font-size: 16px;
          margin: 0;
          text-transform: uppercase;
          font-size: 16px;
          color: #c5a47e;
        }
      }
    }
    .AboutTechniqueText {
      @apply flex flex-col;
      gap: 10px;
      .AboutTechniqueTextTitle {
        @apply uppercase;
        font-family: "Alumni Sans", sans-serif;
        font-size: 80px;
        font-weight: 500;
        letter-spacing: 4px;
      }
      .AboutTechniqueTextDescription {
        color: #999999;
        width: 25vw;
      }
      .AboutTechniqueButtons {
        @apply flex;
        gap: 15px;
        .AboutTechniqueButtonOne {
          width: 150px;
          height: 50px;
          border: 1px solid black;
          transition: 0.3s all;
          border-radius: 2px;

          &:hover {
            scale: 1.05;
          }
          &:active {
            transform: translateY(10px);
          }
        }
      }
      .AboutTechniqueButtonMore {
        width: 250px;
        height: 70px;
        font-size: 20px;
        border: 1px solid black;
        margin-top: 20px;
        transition: 0.3s all;
        border-radius: 2px;

        &:hover {
          scale: 1.05;
        }
        &:active {
          transform: translateY(10px);
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 1740px) {
  .HomeServices {
    .AboutTechnique,
    .AboutWork {
      gap: 50px;
      .AboutTechniqueImg,
      .AboutWorkImg {
        width: 600px;
        height: 380px;
      }
      .AboutTechniqueText {
        .AboutTechniqueTextTitle {
          font-size: 70px;
        }
      }
    }
  }
}
@media (max-width: 1370px) {
  .HomeServices {
    .AboutTechnique,
    .AboutWork {
      gap: 50px;
      .AboutTechniqueImg,
      .AboutWorkImg {
        width: 540px;
        height: 330px;
      }
      .AboutTechniqueText {
        .AboutTechniqueTextTitle {
          font-size: 60px;
        }
        .AboutTechniqueButtons {
          .AboutTechniqueButtonOne {
            width: 130px;
          }
        }
      }
    }
  }
}
@media (max-width: 1240px) {
  .HomeServices {
    .AboutTechnique,
    .AboutWork {
      .AboutTechniqueText {
        .AboutTechniqueTextTitle {
          font-size: 65px;
        }
      }
    }
  }
  .AboutTechnique {
    padding-top: 100px;
    flex-direction: column;
  }
  .AboutWork {
    padding-bottom: 100px;
    flex-direction: column-reverse;
  }
  .HomeServices .AboutTechnique .AboutTechniqueText .AboutTechniqueTextDescription,
  .HomeServices .AboutWork .AboutTechniqueText .AboutTechniqueTextDescription {
    color: #999999;
    width: 42vw;
  }
}
@media (max-width: 700px) {
  .HomeServices {
    .AboutTechnique,
    .AboutWork {
      padding-top: 50px;
      .AboutTechniqueImg,
      .AboutWorkImg {
        width: 500px;
        height: 280px;
        .AboutTechniqueImgText {
          min-width: 200px;
          height: 80px;
          .AboutTechniqueImgTitle {
            font-size: 16px;
          }
          .AboutTechniqueImgDescription {
            font-size: 14px;
          }
        }
      }
      .AboutTechniqueText {
        @apply flex flex-col;
        gap: 10px;
        .AboutTechniqueTextTitle {
          font-size: 55px;
        }
        .AboutTechniqueTextDescription {
          font-size: 15px;
          font-weight: 500;
          width: 430px;
        }
        .AboutTechniqueButtons {
          .AboutTechniqueButtonOne {
            width: 100px;
            height: 40px;
            font-size: 15px;
          }
        }
        .AboutTechniqueButtonMore {
          width: 200px;
          height: 50px;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .HomeServices {
    padding-top: 50px;
    .AboutTechnique,
    .AboutWork {
      padding-top: 25px;
      .AboutTechniqueImg,
      .AboutWorkImg {
        width: 380px;
        height: 220px;
        .AboutTechniqueImgText {
          min-width: 200px;
          height: 90px;
          .AboutTechniqueImgTitle {
            font-size: 16px;
          }
          .AboutTechniqueImgDescription {
            font-size: 13px;
          }
        }
      }
      .AboutTechniqueText {
        .AboutTechniqueTextTitle {
          font-size: 45px;
        }
        .AboutTechniqueTextDescription {
          font-size: 14px;
          font-weight: 500;
          width: 360px;
        }
        .AboutTechniqueButtonMore {
          font-size: 18px;
        }
      }
    }
  }
}
