.OutsourcingCards {
  @apply flex justify-center items-center flex-col;
  padding-top: 150px;
  .OutsourcingCardsBody {
    @apply grid grid-cols-3;
    gap: 50px;
    .OutsourcingImg {
      position: relative;
      width: 500px;
      height: 300px;
      cursor: pointer;
      transition: 0.3s all;

      &:hover {
        scale: 1.01;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      }
      &:hover .OutsourcingImgText {
        width: 100%;
      }
      &:hover .OutsourcingImgText .OutsorcingDescription {
        display: block;
      }
      &:hover .OutsourcingImgText .ImageModalItemBtn {
        display: block;
      }

      .OutsourcingImgBackground {
        width: 100%;
        height: 100%;
        background-size: cover;
        filter: brightness(90%);
      }
      .OutsourcingImgText {
        position: absolute;
        width: 300px;
        bottom: 0;
        left: 0;
        background-color: rgb(255, 255, 255);
        padding: 10px;

        .OutsourcingImgTitle {
          font-family: "Open Sans";
          letter-spacing: -0.5px;
          text-transform: uppercase;
          font-size: 18px;
          margin: 0;
        }

        .OutsorcingDescription {
          font-size: 14px;
          display: none;
        }
      }
    }
  }
}

@media (max-width: 1740px) {
  .OutsourcingCards {
    .OutsourcingCardsTitle {
      font-size: 70px;
    }
    .OutsourcingCardsBody {
      @apply grid grid-cols-2;
    }
  }
}
@media (max-width: 1370px) {
  .OutsourcingCards {
    .OutsourcingCardsTitle {
      font-size: 60px;
    }
    .OutsourcingCardsBody {
      .OutsourcingImg {
        width: 500px;
        height: 330px;
      }
    }
  }
}
@media (max-width: 1240px) {
  .OutsourcingCards {
    padding-bottom: 40px;
    .OutsourcingCardsTitle {
      font-size: 60px;
    }
    .OutsourcingCardsBody {
      .OutsourcingImg {
        width: 450px;
        height: 300px;
      }
    }
  }
}
@media (max-width: 1100px) {
  .OutsourcingCards {
    padding-bottom: 40px;
    .OutsourcingCardsTitle {
      font-size: 65px;
    }
    .OutsourcingCardsBody {
      @apply grid grid-cols-1;
      .OutsourcingImg {
        width: 650px;
        height: 400px;
      }
    }
  }
}
@media (max-width: 900px) {
  .OutsourcingCards {
    padding-bottom: 40px;
    .OutsourcingCardsTitle {
      font-size: 65px;
    }
    .OutsourcingCardsBody {
      @apply grid grid-cols-1;
      .OutsourcingImg {
        width: 600px;
        height: 360px;
      }
    }
  }
}
@media (max-width: 700px) {
  .OutsourcingCards {
    padding-bottom: 80px;
    .OutsourcingCardsTitle {
      font-size: 55px;
    }
    .OutsourcingCardsBody {
      @apply grid grid-cols-1;
      .OutsourcingImg {
        width: 500px;
        height: 300px;
      }
    }
  }
}
@media (max-width: 600px) {
  .OutsourcingCards {
    padding-bottom: 80px;
    .OutsourcingCardsTitle {
      font-size: 45px;
    }
    .OutsourcingCardsBody {
      @apply grid grid-cols-1;
      .OutsourcingImg {
        width: 360px;
        height: 240px;
        .OutsourcingImgText {
          width: 200px;
          padding: 8px;
        }
      }
    }
  }
}
