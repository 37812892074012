.TechnicMain {
  width: 100vw;
  height: calc(100vh - 90px);
  .TechnicMainBackground {
    @apply flex justify-start items-end;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    overflow: hidden;
    background-size: cover;
    background-position: 0% 75%;
    background-repeat: no-repeat;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #2b2b2b;
      background-size: cover;
      opacity: 0.8;
      z-index: 1;
    }
  }
  .TechnicMainData {
    @apply flex flex-col justify-end;
    position: relative;
    z-index: 100;
    color: white;
    padding-left: 128px;
    padding-bottom: 200px;
    height: calc(100vh - 90px);
    .TechnicMainTitle {
      @apply uppercase;
      font-size: 95px;
      font-family: Alumni Sans;
      letter-spacing: 0.06em;
      font-weight: 600;
      padding: 0;
    }
  }
}

@media (max-width: 1240px) {
  .TechnicMain {
    .TechnicMainData {
      .TechnicMainTitle {
        font-size: 85px;
      }
    }
  }
}
@media (max-width: 1000px) {
  .TechnicMain {
    .TechnicMainData {
      padding-left: 64px !important;
    }
  }
}
@media (max-width: 700px) {
  .TechnicMain {
    .TechnicMainData {
      padding-bottom: 150px !important;
    }
  }
}
@media (max-width: 600px) {
  .TechnicMain {
    .TechnicMainData {
      padding-left: 36px !important;
      padding-bottom: 100px !important;
      .TechnicMainTitle {
        font-size: 65px !important;
      }
    }
  }
}
@media (max-width: 400px) {
  .TechnicMain {
    .TechnicMainData {
      padding-left: 16px !important;
      .TechnicMainTitle {
        font-size: 55px !important;
      }
    }
  }
}
