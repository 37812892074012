.ProjectsSlider {
  @apply flex justify-center items-center;
  gap: 200px;
  padding-top: 120px;
  .ProjectsSliderText {
    width: 500px;
    .ProjectsSliderTitle {
      font-size: 50px;
      font-family: "Alumni Sans";
      font-size: 500;
    }
    .ProjectsSliderDescription {
      width: 420px;
      font-weight: 400;
      font-size: 1.25rem;
      color: #5b5b5b;
    }
  }
  .ProjectsSliderBody {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .ProjectsSliderBackground {
      width: 830px;
      height: 450px;
      background-color: #c5a47e;
      z-index: -1;
      top: -30px;
      left: 0;
      position: absolute;
    }
    .ProjectsSliderBackgroundSmall {
      width: 100px;
      height: 100px;
      bottom: 30px;
      left: -15px;
      background-color: #2b2b2b;
      z-index: -1;
      position: absolute;
    }

    .ProjectsSliderSwiper {
      width: 800px;
      height: 600px;
      position: relative;
      z-index: 2;

      .ProjectsSliderSlide {
        width: 800px;
        height: 600px;

        .ProjectsSliderImg {
          object-fit: cover;
          width: 800px;
          height: 550px;
        }
      }
    }
  }
}

@media (max-width: 1650px) {
  .ProjectsSlider {
    gap: 100px;
    .ProjectsSliderText {
      width: 400px;
      .ProjectsSliderTitle {
        font-size: 40px;
      }
      .ProjectsSliderDescription {
        width: 350px;
        font-size: 18px;
      }
    }
    .ProjectsSliderBody {
      .ProjectsSliderBackground {
        width: 730px;
      }

      .ProjectsSliderSwiper {
        width: 700px;
        height: 550px;

        .ProjectsSliderSlide {
          width: 700px;
          height: 550px;

          .ProjectsSliderImg {
            width: 700px;
            height: 500px;
          }
        }
      }
    }
  }
}
@media (max-width: 1340px) {
  .ProjectsSlider {
    @apply flex justify-center items-center flex-col;
    gap: 80px;
    .ProjectsSliderText {
      width: 500px;
      text-align: center;
      .ProjectsSliderTitle {
        font-size: 50px;
      }
      .ProjectsSliderDescription {
        width: 500px;
        font-size: 20px;
      }
    }
    .ProjectsSliderBody {
      .ProjectsSliderBackground {
        width: 830px;
      }

      .ProjectsSliderSwiper {
        width: 800px;
        height: 600px;

        .ProjectsSliderSlide {
          width: 800px;
          height: 600px;

          .ProjectsSliderImg {
            width: 800px;
            height: 550px;
          }
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .ProjectsSlider {
    padding-bottom: 50px;
    .ProjectsSliderText {
      width: 500px;
      text-align: center;
      .ProjectsSliderTitle {
        font-size: 50px;
      }
      .ProjectsSliderDescription {
        width: 500px;
        font-size: 20px;
      }
    }
    .ProjectsSliderBody {
      .ProjectsSliderBackground {
        width: 730px;
      }

      .ProjectsSliderSwiper {
        width: 700px;
        height: 550px;

        .ProjectsSliderSlide {
          width: 700px;
          height: 550px;

          .ProjectsSliderImg {
            width: 700px;
            height: 500px;
          }
        }
      }
    }
  }
}
@media (max-width: 800px) {
  .ProjectsSlider {
    padding-bottom: 50px;
    .ProjectsSliderText {
      width: 400px;
      text-align: center;
      .ProjectsSliderTitle {
        font-size: 40px;
      }
      .ProjectsSliderDescription {
        width: 400px;
        font-size: 18px;
      }
    }
    .ProjectsSliderBody {
      .ProjectsSliderBackground {
        width: 630px;
      }

      .ProjectsSliderSwiper {
        width: 600px;
        height: 500px;

        .ProjectsSliderSlide {
          width: 600px;
          height: 500px;

          .ProjectsSliderImg {
            width: 600px;
            height: 450px;
          }
        }
      }
    }
  }
}
@media (max-width: 700px) {
  .ProjectsSlider {
    padding-bottom: 50px;
    .ProjectsSliderBody {
      .ProjectsSliderBackground {
        width: 530px;
        height: 300px;
      }

      .ProjectsSliderSwiper {
        width: 500px;
        height: 400px;

        .ProjectsSliderSlide {
          width: 500px;
          height: 400px;

          .ProjectsSliderImg {
            width: 500px;
            height: 350px;
          }
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .ProjectsSlider {
    padding-bottom: 50px;
    .ProjectsSliderBody {
      .ProjectsSliderBackground {
        width: 415px;
        height: 250px;
        top: -15px;
      }

      .ProjectsSliderSwiper {
        width: 400px;
        height: 320px;

        .ProjectsSliderSlide {
          width: 400px;
          height: 320px;

          .ProjectsSliderImg {
            width: 400px;
            height: 270px;
          }
        }
      }
    }
  }
}
@media (max-width: 429px) {
  .ProjectsSlider {
    padding-bottom: 50px;
    gap: 50px;
    .ProjectsSliderText {
      width: 320px;
      .ProjectsSliderTitle {
        font-size: 35px;
      }
      .ProjectsSliderDescription {
        width: 320px;
        font-size: 16px;
      }
    }
    .ProjectsSliderBody {
      .ProjectsSliderBackground {
        width: 365px;
        height: 200px;
        top: -15px;
      }

      .ProjectsSliderSwiper {
        width: 350px;
        height: 260px;

        .ProjectsSliderSlide {
          width: 350px;
          height: 260px;

          .ProjectsSliderImg {
            width: 350px;
            height: 220px;
          }
        }
      }
    }
  }
  .swiper-pagination-bullet {
    width: 8px !important;
    height: 8px !important;
  }
}
