.Vacancies {
  @apply flex justify-center items-center flex-col;
  .VacanciesTitle {
    @apply uppercase;
    font-family: "Alumni Sans", sans-serif;
    font-size: 80px;
    font-weight: 500;
    letter-spacing: 4px;
    padding-top: 80px;
  }
  .VacanciesBody {
    @apply flex justify-center items-center flex-wrap;
    gap: 30px;
    .VacanciesItem {
      padding: 15px;
      width: 480px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      .VacanciesImg {
        width: 450px;
        height: auto;
      }
      .VacanciesItemText {
        padding-top: 30px;
        .VacanciesItemTitle {
          @apply uppercase;
          font-family: "Alumni Sans", sans-serif;
          font-size: 32px;
        }
        .VacanciesItemSalary {
          font-family: "Alumni Sans", sans-serif;
          font-size: 20px;
          span {
            font-size: 24px;
            color: #c5a47e;
          }
        }
        .VacanciesItemDetails {
          @apply flex gap-5;
          font-family: "Alumni Sans", sans-serif;
          font-size: 22px;
          padding: 15px 0px;
          .VacanciesItemDetailsEmployment {
            @apply flex gap-1 items-center;
          }
          .VacanciesItemDetailsExperience {
            @apply flex gap-1 items-center;
          }
        }
        .VacanciesItemDescription {
          color: #5b5b5b;
        }
        .VacanciesItemButtons {
          @apply flex;
          padding: 30px 0px;
          gap: 10px;
          .VacanciesItemButtonSend,
          .VacanciesItemButtonPhone {
            @apply uppercase;
            font-family: "Alumni Sans", sans-serif;
            font-size: 22px;
            width: 220px;
            height: 65px;
            transition: 0.3s all;
            &:hover {
              scale: 1.01;
            }
            &:active {
              transform: translateY(10px);
            }
          }
          .VacanciesItemButtonSend {
            border: 2px solid black;
          }
          .VacanciesItemButtonPhone {
            background-color: #c5a47e;
            color: white;
          }
        }
      }
    }
  }
}
@media (max-width: 1740px) {
  .Vacancies {
    .VacanciesTitle {
      font-size: 70px;
    }
  }
}
@media (max-width: 1240px) {
  .Vacancies {
    .VacanciesTitle {
      font-size: 65px;
    }
  }
}
@media (max-width: 700px) {
  .Vacancies {
    .VacanciesTitle {
      font-size: 55px;
    }
  }
}
@media (max-width: 600px) {
  .Vacancies {
    .VacanciesTitle {
      font-size: 45px;
    }
  }
}
@media (max-width: 500px) {
  .Vacancies {
    .VacanciesBody {
      .VacanciesItem {
        width: 400px;
        .VacanciesImg {
          width: 370px;
        }
        .VacanciesItemText {
          padding-top: 30px;
          .VacanciesItemTitle {
            font-size: 28px;
          }
          .VacanciesItemDetails {
            @apply flex gap-3;
            font-size: 20px;
          }
          .VacanciesItemButtons {
            @apply flex;
            padding: 30px 0px;
            gap: 10px;
            .VacanciesItemButtonSend,
            .VacanciesItemButtonPhone {
              height: 50px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 430px) {
  .Vacancies {
    .VacanciesBody {
      .VacanciesItem {
        padding: 15px;
        width: 350px;
        .VacanciesImg {
          width: 320px;
        }
        .VacanciesItemText {
          padding-top: 30px;
          .VacanciesItemTitle {
            font-size: 28px;
          }
          .VacanciesItemDetails {
            @apply flex gap-3;
            font-size: 20px;
          }
          .VacanciesItemButtons {
            @apply flex;
            padding: 30px 0px;
            gap: 10px;
            .VacanciesItemButtonSend,
            .VacanciesItemButtonPhone {
              height: 50px;
            }
          }
        }
      }
    }
  }
}
