.Phone {
  position: fixed;
  bottom: 200px;
  right: 200px;
  z-index: 2000;
  transition: 0.3s all;

  .PhoneButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    // border: 3px solid;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 60px;
    height: 60px;
    transition: 0.3s all;
    position: fixed;
    z-index: 2999;
    background-color: white;

    &:hover {
      scale: 1.05;
    }

    &:active {
      transform: translateY(10px);
    }
  }
}

@keyframes escape {
  0% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: -1000px;
    opacity: 0;
  }
}

.preloader_body {
  @apply flex justify-center items-center;
  width: 460px;
  height: 460px;
  .preloader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #c5a47e; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Preloader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  animation: 0.5s escape;
  animation-delay: 3s;
  .PreloaderPattern {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
  }
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 90px;
    height: 103px;
  }

  .loader div {
    position: absolute;
    width: 50px;
    height: 31px;
  }

  .rot {
    transform: rotate(150deg);
  }

  .rot2 {
    transform: rotate(20deg);
  }

  .loader div:nth-of-type(2) {
    transform: rotate(60deg);
  }

  .loader div:nth-of-type(3) {
    transform: rotate(-60deg);
  }

  .loader div div {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .loader div div span {
    position: absolute;
    width: 4px;
    height: 0%;
    background: #053146;
    z-index: 999999;
  }

  .h1 {
    left: 0;
    animation: load1 3.2s ease infinite;
  }

  .h2 {
    right: 0;
    animation: load2 3.2s ease 0.4s infinite;
  }

  .h3 {
    right: 0;
    animation: load3 3.2s ease 0.8s infinite;
  }

  .h4 {
    top: 10px;
    left: 23px;
    animation: load4 3.2s ease 1s infinite;
    transform: rotate(90deg);
  }

  .h5 {
    bottom: 0;
    animation: load5 3.2s ease 1.2s infinite;
  }

  .h6 {
    left: 0;
    animation: load6 3.2s ease 1.3s infinite;
  }

  @keyframes load1 {
    0% {
      bottom: 0;
      height: 0;
    }

    6.944444444% {
      bottom: 0;
      height: 100%;
    }

    50% {
      top: 0;
      height: 100%;
    }

    59.944444433% {
      top: 0;
      height: 0;
    }
    /*   91.6666667%{top:0;height:0%;} */
  }

  @keyframes load2 {
    0% {
      top: 0;
      height: 0;
    }

    6.944444444% {
      top: 0;
      height: 100%;
    }

    50% {
      bottom: 0;
      height: 100%;
    }

    59.944444433% {
      bottom: 0;
      height: 0;
    }
    /*   91.6666667%{bottom:0;height:0%} */
  }

  @keyframes load3 {
    0% {
      top: 0;
      height: 0;
    }

    6.944444444% {
      top: 0;
      height: 100%;
    }

    50% {
      bottom: 0;
      height: 100%;
    }

    59.94444443% {
      bottom: 0;
      height: 0;
    }
    /*   91.6666667%{bottom:0;height:0%;} */
  }

  @keyframes load4 {
    0% {
      top: 37px;
      left: 23px;
      height: 134%;
    }

    6.944444444% {
      top: 10px;
      height: 134%;
    }

    50% {
      bottom: 10px;
      height: 134%;
    }

    59.94444443% {
      bottom: 0;
      height: 0;
    }
    /*   91.6666667%{bottom:0;height:0%;} */
  }

  @keyframes load5 {
    0% {
      bottom: 0;
      height: 0;
    }

    6.944444444% {
      bottom: 0;
      height: 100%;
    }

    50% {
      top: 0;
      height: 100%;
    }

    59.94444443% {
      top: 0;
      height: 0;
    }
    /*   91.6666667%{top:0;height:0%;} */
  }

  @keyframes load6 {
    0% {
      bottom: 0;
      height: 0;
    }

    6.944444444% {
      bottom: 0;
      height: 100%;
    }

    50% {
      top: 0;
      height: 100%;
    }

    59.94444443% {
      top: 0;
      height: 0;
    }
    /*   91.6666667%{top:0;height:0%;} */
  }
}
.BtnUp {
  position: fixed;
  bottom: 120px;
  right: 200px;
  z-index: 2000;
  transition: 0.3s all;

  .BtnUpButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 60px;
    height: 60px;
    transition: 0.3s all;
    position: fixed;
    z-index: 2999;
    background-color: rgba($color: #c5a47e, $alpha: 0.6);

    &:hover {
      scale: 1.05;
    }

    &:active {
      transform: translateY(10px);
    }
  }
}

.PhoneColor {
  transition: 0.3s all;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2b2b2b80;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 450px;
  height: 600px;
  // display: flex;
  // justify-content: center;
  // flex-direction: column;

  .modal-header {
    display: flex;
    justify-content: end;

    button {
      background-color: transparent;
      width: 40px;
      height: 40px;
      padding: 0;
      margin: 0;
      border-radius: 50%;
      transition: 0.3s all;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      -webkit-transition: 0.3s all;
      -moz-transition: 0.3s all;
      -ms-transition: 0.3s all;
      -o-transition: 0.3s all;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: #dbdbdb;
      }
    }
  }

  h2 {
    font-family: Alumni Sans;
    font-size: 70px;
    font-weight: 500;
    line-height: 84px;
    letter-spacing: -0.01em;
    text-align: center;
    color: #000;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 0 auto;
  }

  input {
    display: flex;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 4px;
    border-top: none;
    border-right: none;
    border-left: none;
    width: 339px;
    height: 65px;
    background-color: #edededcc;
    border-bottom: 1px solid rgba(237, 237, 237, 0.2);
    outline: none;
    margin-top: 30px;
  }

  button {
    border: none;
    color: #fff;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:active {
      transform: translateY(1px);
    }
  }

  .modal-btn {
    background-color: #ffffff !important;
    border: 1px solid #000 !important;
    width: 180px;
    height: 52px;
    border-radius: 0 !important;
    color: #000 !important;
    text-transform: uppercase !important;
  }
}

.footer__attach {
  @apply flex items-center justify-start;
  gap: 10px;
}

.footer__attach-btn {
  @apply flex items-center justify-center;
  border-radius: 50%;
  background-color: white !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 45px;
  height: 45px;
  padding: 0px;
}

.technics__toptitle {
  color: #000;
  text-transform: uppercase;
}

@media only screen and (min-width: 320px) and (max-width: 1980px) {
  .Phone,
  .BtnUp {
    right: 150px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 1620px) {
  .Phone,
  .BtnUp {
    right: 100px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 1340px) {
  .Phone,
  .BtnUp {
    right: 80px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 1240px) {
  .Phone,
  .BtnUp {
    right: 120px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 850px) {
  .Phone,
  .BtnUp {
    right: 80px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 650px) {
  .modal-content {
    width: 400px;
    height: 500px;
    h2 {
      font-size: 45px;
    }
    form {
      gap: 20px;
      input {
        margin: 0;
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 500px) {
  .Phone {
    bottom: 120px;
    right: 60px;
    .PhoneButton {
      width: 50px;
      height: 50px;
      svg {
        width: 30px;
      }
    }
  }
  .BtnUp {
    bottom: 60px;
    right: 60px;
    .BtnUpButton {
      width: 50px;
      height: 50px;
      svg {
        width: 30px;
      }
    }
  }
}
@media only screen and (min-width: 30px) and (max-width: 420px) {
  .modal-content {
    width: 350px;
    height: 450px;
    padding: 10px;
    h2 {
      font-size: 45px;
    }
    form {
      gap: 20px;
      input {
        width: 250px;
        height: 50px;
        margin: 0;
      }
    }
  }
}
