.AboutUsYears {
  min-height: 500px;
  .AboutUsYearsBody {
    @apply grid grid-cols-4;
    .AboutUsYearsItem {
      background-color: white;
      position: relative;
      z-index: 100;
      border: 1px solid #999999;
      width: 100%;
      height: 500px;
      cursor: pointer;
      transition: 0.5s all;

      &:hover .AboutUsYearsItemImgBackground {
        opacity: 1;
      }
      &:hover .AboutUsYearsItemData .AboutUsYearsItemText .AboutUsYearsItemTitle {
        color: white;
      }
      .AboutUsYearsItemImgBackground {
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 500px;
        background-size: cover;
        filter: brightness(60%);
        transition: 0.5s all;
        opacity: 0;
      }
      .AboutUsYearsItemData {
        @apply flex flex-col justify-between;
        height: 500px;
        position: relative;
        z-index: 100;
        padding: 10px;
        .AboutUsYearsItemNumber {
          font-family: "Khand", sans-serif;
          font-weight: 600;
          font-size: 100px;
          height: 230px;
          color: transparent;
          background-clip: text;
          background-size: 420px;
          background-position: center;
          -webkit-text-stroke: 1.5px #c5a47e;
          padding: 0px 10px;
        }
        .AboutUsYearsItemText {
          .AboutUsYearsItemDescription {
            color: white;
          }
          .AboutUsYearsItemTitle {
            font-family: "Alumni Sans", sans-serif;
            font-size: 40px;
            color: black;
            padding-bottom: 40px;
            transition: 0.5;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 1840px) {
  .AboutUsYears {
    .AboutUsYearsBody {
      .AboutUsYearsItem {
        .AboutUsYearsItemData {
          .AboutUsYearsItemNumber {
            font-size: 90px;
          }
          .AboutUsYearsItemText {
            .AboutUsYearsItemTitle {
              font-size: 35px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 1640px) {
  .AboutUsYears {
    .AboutUsYearsBody {
      @apply grid grid-cols-2;
      .AboutUsYearsItem {
        .AboutUsYearsItemData {
          .AboutUsYearsItemNumber {
            font-size: 90px;
          }
          .AboutUsYearsItemText {
            .AboutUsYearsItemTitle {
              font-size: 35px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 1240px) {
  .AboutUsYears {
    padding-top: 100px;
    .AboutUsYearsBody {
      @apply grid grid-cols-2;
      .AboutUsYearsItem {
        .AboutUsYearsItemData {
          .AboutUsYearsItemNumber {
            font-size: 90px;
          }
          .AboutUsYearsItemText {
            .AboutUsYearsItemTitle {
              font-size: 35px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 940px) {
  .AboutUsYears {
    padding-top: 100px;
    .AboutUsYearsBody {
      @apply grid grid-cols-1;
      .AboutUsYearsItem {
        .AboutUsYearsItemData {
          .AboutUsYearsItemNumber {
            font-size: 90px;
          }
          .AboutUsYearsItemText {
            .AboutUsYearsItemTitle {
              font-size: 35px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 700px) {
  .AboutUsYears {
    .AboutUsYearsBody {
      .AboutUsYearsItem {
        height: 400px;
        .AboutUsYearsItemImgBackground {
          height: 400px;
        }
        .AboutUsYearsItemData {
          height: 400px;
        }
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .AboutUsYears {
    .AboutUsYearsBody {
      .AboutUsYearsItem {
        .AboutUsYearsItemData {
          .AboutUsYearsItemNumber {
            font-size: 70px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 500px) {
  .AboutUsYears {
    .AboutUsYearsBody {
      .AboutUsYearsItem {
        height: 350px;
        .AboutUsYearsItemImgBackground {
          height: 350px;
        }
        .AboutUsYearsItemData {
          height: 350px;
          .AboutUsYearsItemNumber {
            font-size: 70px;
          }
          .AboutUsYearsItemText {
            .AboutUsYearsItemTitle {
              font-size: 28px;
            }
            .AboutUsYearsItemDescription {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
