.WorkCards {
  @apply flex justify-center items-center flex-col;
  padding-top: 150px;
  .WorkCardsBody {
    @apply grid grid-cols-3;
    gap: 50px;

    .WorkImg {
      position: relative;
      width: 500px;
      height: 300px;
      cursor: pointer;
      transition: 0.3s all;

      &:hover .WorkImgTextBig {
        width: 100%;
      }
      &:hover .WorkImgTextBig .ImageModalItemBtn {
        display: block;
      }

      &:hover {
        scale: 1.01;
      }

      .WorkImgBackground {
        width: 100%;
        height: 100%;
        background-size: cover;
        filter: brightness(90%);
      }
      .WorkImgText {
        position: absolute;
        width: 300px;
        min-height: 50px;
        bottom: 0;
        left: 0;
        background-color: rgb(255, 255, 255);
        padding: 10px;

        .WorkImgTitle {
          font-family: "Open Sans";
          text-transform: uppercase;
          font-size: 16px;
          margin: 0;
        }
      }
      .WorkImgTextBig {
        position: absolute;
        width: 300px;
        bottom: 0;
        left: 0;
        background-color: rgb(255, 255, 255);
        padding: 10px;
        .WorkImgTitleBig {
          font-family: "Open Sans";
          letter-spacing: -0.5px;
          text-transform: uppercase;
          font-size: 16px;
          margin: 0;
        }
      }
    }
  }
}

@media (max-width: 1740px) {
  .WorkCards {
    .WorkCardsTitle {
      font-size: 70px;
    }
    .WorkCardsBody {
      @apply grid grid-cols-2;
    }
  }
}
@media (max-width: 1370px) {
  .WorkCards {
    .WorkCardsTitle {
      font-size: 60px;
    }
    .WorkCardsBody {
      .WorkImg {
        width: 500px;
        height: 330px;
      }
    }
  }
}
@media (max-width: 1240px) {
  .WorkCards {
    padding-bottom: 40px;
    .WorkCardsTitle {
      font-size: 60px;
    }
    .WorkCardsBody {
      .WorkImg {
        width: 450px;
        height: 300px;
      }
    }
  }
}
@media (max-width: 1100px) {
  .WorkCards {
    padding-bottom: 40px;
    .WorkCardsTitle {
      font-size: 65px;
    }
    .WorkCardsBody {
      @apply grid grid-cols-1;
      .WorkImg {
        width: 650px;
        height: 400px;
      }
    }
  }
}
@media (max-width: 900px) {
  .WorkCards {
    padding-bottom: 40px;
    .WorkCardsTitle {
      font-size: 65px;
    }
    .WorkCardsBody {
      @apply grid grid-cols-1;
      .WorkImg {
        width: 600px;
        height: 360px;
      }
    }
  }
}
@media (max-width: 700px) {
  .WorkCards {
    padding-bottom: 80px;
    .WorkCardsTitle {
      font-size: 55px;
    }
    .WorkCardsBody {
      @apply grid grid-cols-1;
      .WorkImg {
        width: 500px;
        height: 300px;
      }
    }
  }
}
@media (max-width: 600px) {
  .WorkCards {
    padding-bottom: 80px;
    .WorkCardsTitle {
      font-size: 45px;
    }
    .WorkCardsBody {
      @apply grid grid-cols-1;
      .WorkImg {
        width: 360px;
        height: 240px;
        .WorkImgText {
          width: 150px;
          padding: 8px;

          .WorkImgTitle {
            font-size: 25px;
          }

          .WorkImgPrice {
            font-size: 18px;
          }
        }
      }
    }
  }
}
