.RecyclablesAbout {
  @apply flex flex-col justify-center items-center;
  padding-top: 70px;
  .RecyclablesContainer {
    @apply flex justify-center items-center;
    gap: 100px;
    .RecyclablesContent {
      .RecyclablesContentSmallTitle {
        @apply uppercase;
        font-weight: 600;
        font-size: 1.25rem;
        letter-spacing: 7px;
        color: #c5a47e;
        font-family: Alumni Sans;
        margin-top: 1.875rem;
      }
      .RecyclablesContentTitle {
        @apply uppercase;
        font-weight: 500;
        font-family: Alumni Sans;
        font-size: 80px;
        letter-spacing: 6%;
        color: #000000;
        margin-bottom: 2.5rem;
      }
      .RecyclablesText {
        margin-top: 40px;
        margin-top: 0rem;
        margin-bottom: 0rem;
        font-weight: 400;
        font-size: 1rem;
        color: #999999;
        line-height: 19px;
        max-width: 613px;
      }
      .RecyclablesButton {
        margin-top: 2.5rem;
        background: white;
        border: 0.0625rem solid black;
        color: black;
        padding: 0.9375rem 2.5rem;
        margin-right: 1.25rem;
        font-size: 1rem;
        transition: 0.3s all;

        &:hover {
          scale: 1.05;
        }
      }
    }
  }
}

@media (max-width: 1740px) {
  .RecyclablesAbout {
    .RecyclablesTitle {
      font-size: 70px;
    }
    .RecyclablesContainer {
      gap: 80px;
      .RecyclablesContent {
        .RecyclablesContentTitle {
          font-size: 70px;
        }
        .RecyclablesText {
          width: 450px;
        }
      }
    }
  }
}
@media (max-width: 1370px) {
  .RecyclablesAbout {
    .RecyclablesTitle {
      font-size: 60px;
    }
    .RecyclablesContainer {
      gap: 80px;
      .RecyclablesContent {
        .RecyclablesContentTitle {
          font-size: 60px;
        }
      }
      .RecyclablesDecoration {
        img {
          width: 500px;
          height: auto;
        }
      }
    }
  }
}
@media (max-width: 1240px) {
  .RecyclablesAbout {
    .RecyclablesTitle {
      font-size: 60px;
    }
    .RecyclablesContainer {
      gap: 80px;
      .RecyclablesContent {
        .RecyclablesContentTitle {
          font-size: 60px;
        }
        .RecyclablesText {
          width: 400px;
          font-size: 15px;
        }
      }
      .RecyclablesDecoration {
        img {
          width: 460px;
          height: auto;
        }
      }
    }
  }
}
@media (max-width: 1100px) {
  .RecyclablesAbout {
    .RecyclablesTitle {
      font-size: 65px;
    }
    .RecyclablesContainer {
      @apply flex justify-center items-center flex-col-reverse;
      gap: 20px;
      .RecyclablesContent {
        @apply flex flex-col justify-center items-center;
        .RecyclablesContentTitle {
          font-size: 65px;
        }
        .RecyclablesText {
          text-align: center;
          width: 600px;
          font-size: 16px;
        }
      }
      .RecyclablesDecoration {
        img {
          width: 620px;
          height: auto;
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .RecyclablesAbout {
    .RecyclablesTitle {
      font-size: 65px;
    }
    .RecyclablesContainer {
      gap: 20px;
      .RecyclablesContent {
        .RecyclablesContentTitle {
          font-size: 65px;
        }
        .RecyclablesText {
          text-align: center;
          width: 550px;
          font-size: 16px;
        }
      }
      .RecyclablesDecoration {
        img {
          width: 600px;
          height: auto;
        }
      }
    }
  }
}
@media (max-width: 700px) {
  .RecyclablesAbout {
    .RecyclablesTitle {
      font-size: 55px;
    }
    .RecyclablesContainer {
      gap: 20px;
      .RecyclablesContent {
        .RecyclablesContentTitle {
          font-size: 55px;
        }
        .RecyclablesText {
          text-align: center;
          width: 450px;
          font-size: 16px;
        }
      }
      .RecyclablesDecoration {
        img {
          width: 500px;
          height: auto;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .RecyclablesAbout {
    .RecyclablesTitle {
      font-size: 45px;
    }
    .RecyclablesContainer {
      gap: 20px;
      .RecyclablesContent {
        .RecyclablesContentTitle {
          font-size: 45px;
          margin-bottom: 15px;
        }
        .RecyclablesText {
          text-align: center;
          width: 360px;
          font-size: 15px;
        }
      }
      .RecyclablesDecoration {
        img {
          width: 360px;
          height: auto;
        }
      }
    }
  }
}
