.HousesCards {
  @apply flex justify-center items-center flex-col;
  padding-top: 150px;
  .HousesCardsBody {
    @apply grid grid-cols-3;
    gap: 50px;
    .HousesImg {
      position: relative;
      width: 500px;
      height: 300px;
      cursor: pointer;
      transition: 0.3s all;

      &:hover {
        scale: 1.01;
      }

      .HousesImgBackground {
        width: 100%;
        height: 100%;
        background-size: cover;
        filter: brightness(90%);
      }
      .HousesImgText {
        position: absolute;
        width: 300px;
        bottom: 0;
        left: 0;
        background-color: rgb(255, 255, 255);
        padding: 10px;

        .HousesImgTitle {
          font-family: "Open Sans";
          letter-spacing: -0.5px;
          text-transform: uppercase;
          font-size: 18px;
          margin: 0;
        }

        .HousesImgPrice {
          font-family: "Open Sans";
          letter-spacing: -1.5px;
          font-size: 16px;
          margin: 0;
          text-transform: uppercase;
          font-size: 16px;
          color: #c5a47e;
        }
      }
    }
  }
}

@media (max-width: 1740px) {
  .HousesCards {
    .HousesCardsTitle {
      font-size: 70px;
    }
    .HousesCardsBody {
      @apply grid grid-cols-2;
    }
  }
}
@media (max-width: 1370px) {
  .HousesCards {
    .HousesCardsTitle {
      font-size: 60px;
    }
    .HousesCardsBody {
      .HousesImg {
        width: 500px;
        height: 330px;
      }
    }
  }
}
@media (max-width: 1240px) {
  .HousesCards {
    padding-bottom: 40px;
    .HousesCardsTitle {
      font-size: 60px;
    }
    .HousesCardsBody {
      .HousesImg {
        width: 450px;
        height: 300px;
      }
    }
  }
}
@media (max-width: 1100px) {
  .HousesCards {
    padding-bottom: 40px;
    .HousesCardsTitle {
      font-size: 65px;
    }
    .HousesCardsBody {
      @apply grid grid-cols-1;
      .HousesImg {
        width: 650px;
        height: 400px;
      }
    }
  }
}
@media (max-width: 900px) {
  .HousesCards {
    padding-bottom: 40px;
    .HousesCardsTitle {
      font-size: 65px;
    }
    .HousesCardsBody {
      @apply grid grid-cols-1;
      .HousesImg {
        width: 600px;
        height: 360px;
      }
    }
  }
}
@media (max-width: 700px) {
  .HousesCards {
    padding-bottom: 80px;
    .HousesCardsTitle {
      font-size: 55px;
    }
    .HousesCardsBody {
      @apply grid grid-cols-1;
      .HousesImg {
        width: 500px;
        height: 300px;
      }
    }
  }
}
@media (max-width: 600px) {
  .HousesCards {
    padding-bottom: 80px;
    .HousesCardsTitle {
      font-size: 45px;
    }
    .HousesCardsBody {
      @apply grid grid-cols-1;
      .HousesImg {
        width: 360px;
        height: 240px;
        .HousesImgText {
          width: 250px;
          padding: 8px;
        }
      }
    }
  }
}
