.InfoMainAbout {
  @apply flex justify-center items-center;
  gap: 50px;
  min-height: 900px;
  .InfoMainAboutContainer {
    max-width: 1200px;
    .InfoMainImage {
      @apply flex justify-center items-center;
      width: 420px;
      height: 420px;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      .InfoMainImageBlock {
        @apply flex justify-center items-center flex-col;
        width: 360px;
        height: 360px;
        background-color: rgba(255, 255, 255, 0.8);
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 20;

        .InfoMainImageNumber {
          font-family: "Khand", sans-serif;
          font-weight: 600;
          font-size: 180px;
          height: 230px;
          color: transparent;
          background-clip: text;
          background-size: 420px;
          background-position: center;
          -webkit-text-stroke: 2px #c5a47e;
        }
        .InfoMainImageText {
          @apply uppercase;
          color: #c5a47e;
          font-weight: 500;
        }
      }
    }
    .InfoAboutUs {
      @apply uppercase;
      color: #c5a47e;
      font-family: "Alumni Sans", sans-serif;
      font-weight: 500;
      letter-spacing: 6px;
      font-size: 20px;
      padding-top: 20px;
    }
    .InfoAboutCompany {
      @apply uppercase;
      font-family: "Alumni Sans", sans-serif;
      font-size: 80px;
      font-weight: 500;
      letter-spacing: 4px;
    }
    .InfoAboutCompanyDescription {
      width: 600px;
      color: #999999;
      font-size: 16px;
    }
    .InfoMainAboutButton {
      width: 130px;
      height: 50px;
      border: 2px solid black;
      margin-top: 20px;
      transition: 0.3s all;
      border-radius: 2px;

      &:hover {
        scale: 1.05;
      }
      &:active {
        transform: translateY(10px);
      }
    }
  }
  .AboutInfoPhoto {
    img {
      height: 720px;
      width: auto;
      border-radius: 2px;
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 1740px) {
  .InfoMainAbout {
    .InfoMainAboutContainer {
      .InfoMainImage {
        width: 360px;
        height: 360px;
        .InfoMainImageBlock {
          @apply flex justify-center items-center flex-col;
          width: 300px;
          height: 300px;
          .InfoMainImageNumber {
            font-size: 160px;
            height: 200px;
          }
        }
      }
      .InfoAboutCompany {
        font-size: 70px;
      }
      .InfoAboutCompanyDescription {
        width: 500px;
      }
    }
  }
  .InfoPhoto {
    img {
      width: 570px;
    }
  }
}
@media (max-width: 1370px) {
  .InfoMainAbout {
    .InfoMainAboutContainer {
      .InfoMainImage {
        width: 340px;
        height: 340px;
        .InfoMainImageBlock {
          @apply flex justify-center items-center flex-col;
          width: 280px;
          height: 280px;
          .InfoMainImageNumber {
            font-size: 150px;
            height: 180px;
          }
        }
      }
      .InfoAboutCompany {
        font-size: 60px;
      }
      .InfoAboutCompanyDescription {
        width: 450px;
      }
    }
  }
  .InfoPhoto {
    img {
      width: 530px;
      object-fit: cover;
    }
  }
}
@media (max-width: 1240px) {
  .InfoMainAbout {
    .InfoMainAboutContainer {
      .InfoMainImage {
        width: 340px;
        height: 340px;
        .InfoMainImageBlock {
          @apply flex justify-center items-center flex-col;
          width: 280px;
          height: 280px;
          .InfoMainImageNumber {
            font-size: 150px;
            height: 180px;
          }
        }
      }
      .InfoAboutCompany {
        font-size: 60px;
      }
      .InfoAboutCompanyDescription {
        width: 450px;
      }
    }
  }
  .InfoPhoto {
    img {
      width: 530px;
      object-fit: cover;
    }
  }
}
@media (max-width: 1240px) {
  .InfoMainAbout {
    @apply flex-col;
    .InfoMainAboutContainer {
      @apply flex justify-center items-center flex-col;
      .InfoMainImage {
        width: 420px;
        height: 420px;
        .InfoMainImageBlock {
          @apply flex justify-center items-center flex-col;
          width: 360px;
          height: 360px;
          .InfoMainImageNumber {
            font-size: 180px;
            height: 250px;
          }
        }
      }
      .InfoAboutCompany {
        font-size: 65px;
      }
      .InfoAboutCompanyDescription {
        width: 500px;
      }
    }
  }
}
@media (max-width: 700px) {
  .InfoMainAbout {
    @apply flex-col;
    .InfoMainAboutContainer {
      @apply flex justify-center items-center flex-col;
      .InfoMainImage {
        width: 360px;
        height: 360px;
        .InfoMainImageBlock {
          @apply flex justify-center items-center flex-col;
          width: 300px;
          height: 300px;
          .InfoMainImageNumber {
            font-size: 160px;
            height: 200px;
          }
        }
      }
      .InfoAboutCompany {
        font-size: 55px;
      }
      .InfoAboutCompanyDescription {
        width: 430px;
        font-size: 15px;
        font-weight: 500;
      }
    }
    .InfoPhoto {
      img {
        width: 430px;
        object-fit: cover;
      }
    }
  }
}
@media (max-width: 600px) {
  .InfoMainAbout {
    @apply flex-col;
    .InfoMainAboutContainer {
      @apply flex justify-center items-center flex-col;
      .InfoMainImage {
        width: 300px;
        height: 300px;
        .InfoMainImageBlock {
          @apply flex justify-center items-center flex-col;
          width: 260px;
          height: 260px;
          .InfoMainImageNumber {
            font-size: 140px;
            height: 180px;
          }
        }
      }
      .InfoAboutCompany {
        font-size: 45px;
      }
      .InfoAboutCompanyDescription {
        width: 360px;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .AboutInfoPhoto {
      img {
        width: 380px;
        height: auto;
      }
    }
  }
}
