.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  height: 100px;
  width: 100vw;
  padding: 0px 128px;
  position: relative;
  border-bottom: 1px solid white;
  .HeaderLogo {
    @apply flex items-center;
    gap: 5px;
    .HeaderTitle {
      font-size: 40px;
      font-weight: 600;
      text-transform: uppercase;
      margin-top: 10px;
    }
  }
  .HeaderRoutes {
    @apply flex items-center justify-center uppercase;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    gap: 25px;
    font-size: 16px;
    overflow: hidden;
    padding-top: 10px;
    .HeaderDropdownButton {
      @apply uppercase;
    }

    .Dropdown {
      height: 500px;
      @apply flex items-center;
      cursor: pointer;
      .HeaderDropdownServices {
        @apply flex flex-col justify-center items-center gap-3 uppercase;
        position: absolute;
        display: none !important;
        top: 70px;
        border: 1px solid white;
        padding: 20px;
        border-radius: 2px;
        backdrop-filter: blur(6px);
        z-index: 300;

        .HeaderServicesRouteItem {
          transition: 0.3s all;
          position: relative;

          &:after {
            content: "";
            width: 100%;
            height: 2px;
            background-color: #ffffff;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            transition: opacity 0.3s ease;
          }

          &:hover:after {
            opacity: 1;
          }
          &:active {
            transform: translateY(10px);
          }
        }
      }
      &:hover .HeaderDropdownServices {
        display: flex !important;
      }
    }

    .HeaderRouteItem {
      @apply uppercase;
      transition: 0.3s all;
      position: relative;

      &:after {
        content: "";
        width: 100%;
        height: 2px;
        background-color: #ffffff;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      &:hover:after {
        opacity: 1;
      }
      &:active {
        transform: translateY(10px);
      }
    }
  }

  .dropdown {
    &-content {
      display: none !important;
    }
    &:hover .dropdown-content {
      display: block !important;
    }
    &-content-container {
      backdrop-filter: blur(8px) !important;
      height: 89px;
      position: absolute;
      display: flex;
      flex-direction: column;
      border: 1px solid #ffffff;
      padding: 5px 20px 5px 20px;
      z-index: 777;
    }
    &-content-container a {
      padding: 5px;
    }
  }
}
.hideOption {
  display: none;
}
.headerMobile {
  @apply flex justify-center items-center gap-5;
  padding-top: 10px;
}
.Modal {
  opacity: 1;
  color: #111111;
  position: fixed;
  right: -250px;
  top: 0;
  width: 250px;
  background-color: white;
  height: 100vh;
  transition: 0.3s all;
  z-index: 3000;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  .ModalHead {
    @apply flex justify-end items-center;
    height: 80px;
    width: 250px;
    .CloseButton {
      @apply flex justify-center items-center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 15px;
      transition: 0.3s all;

      &:hover {
        background-color: rgba($color: #545353, $alpha: 0.4);
        scale: 1.1;
      }
      &:active {
        transform: translateY(10px);
      }
    }
  }
  .ModalBody {
    @apply flex flex-col gap-8 items-start;
    padding-left: 30px;
    .modalNavItem {
      text-align: center;
      text-transform: uppercase;
      padding: 0 20px;
      transition: 0.3s all;
      position: relative;
      font-size: 16px;

      &:after {
        content: "";
        width: 80%;
        height: 2px;
        background-color: #111111;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      &:hover:after {
        opacity: 1;
      }
      &:active {
        transform: translateY(10px);
      }
    }
  }
}
.show {
  right: 0px !important;
}
.HeaderLanguage {
  @apply flex justify-between items-center gap-2;
  font-family: "Alumni Sans", sans-serif;
  font-size: 32px;

  svg {
    transition: 0.3s all;
    margin-right: 5px;
  }

  &:hover svg {
    scale: 1.1;
  }
  .LangDropdown {
    height: 500px;
    @apply flex items-center;
    cursor: pointer;
    .HeaderDropdownServices {
      @apply flex flex-col justify-center items-center gap-3 uppercase;
      position: absolute;
      display: none !important;
      top: 70px;
      border: 1px solid white;
      padding: 20px;
      border-radius: 2px;
      backdrop-filter: blur(6px);
      z-index: 300;

      .HeaderServicesRouteItem {
        transition: 0.3s all;
        position: relative;

        &:after {
          content: "";
          width: 100%;
          height: 2px;
          background-color: #ffffff;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          opacity: 0;
          transition: opacity 0.3s ease;
        }

        &:hover:after {
          opacity: 1;
        }
        &:active {
          transform: translateY(10px);
        }
      }
    }
    &:hover .HeaderDropdownServices {
      display: flex !important;
    }
  }
}

@keyframes bell {
  0% {
    transform: rotateX("0deg");
  }
  25% {
    transform: rotateX("25deg");
  }
  50% {
    transform: rotateX("0deg");
  }
  75% {
    transform: rotateX("-25deg");
  }
  100% {
    transform: rotateX("0deg");
  }
}

@media (max-width: 1000px) {
  .Header {
    padding: 0px 64px;
    .HeaderLogo {
      img {
        width: 50px;
        height: auto;
      }
      .HeaderTitle {
        font-size: 35px;
      }
    }
  }
}
@media (max-width: 600px) {
  .Header {
    padding: 0px 36px;
    .HeaderLogo {
      img {
        width: 50px;
        height: auto;
      }
      .HeaderTitle {
        font-size: 35px;
      }
    }
  }
}
@media (max-width: 500px) {
  .Header {
    .HeaderLogo {
      img {
        width: 45px;
        height: auto;
      }
      .HeaderTitle {
        font-size: 30px;
      }
    }
    .HeaderLanguage {
      font-size: 26px;
    }
  }
}
@media (max-width: 450px) {
  .Header {
    .HeaderLogo {
      img {
        width: 45px;
        height: auto;
      }
      .HeaderTitle {
        font-size: 30px;
      }
    }
  }
}
@media (max-width: 400px) {
  .Header {
    padding: 0px 16px;
    .HeaderLogo {
      gap: 1px;
      .HeaderTitle {
        font-size: 27px;
      }
      img {
        width: 40px;
        height: auto;
      }
    }
    .headerMobile {
      gap: 10px;
    }
  }
}
