.CurrTechnicCards {
  @apply flex justify-center items-center flex-col;
  .CurrTechnicCardsTitle {
    @apply uppercase;
    font-family: "Alumni Sans", sans-serif;
    font-size: 80px;
    font-weight: 500;
    letter-spacing: 4px;
    padding: 40px 0px;
  }
  .CurrTechnicCardsBody {
    @apply grid grid-cols-2;
    gap: 50px;
    .CurrTechniqueImg {
      position: relative;
      width: 650px;
      height: 400px;
      cursor: pointer;
      transition: 0.3s all;

      &:hover {
        scale: 1.01;
      }

      &:hover .CurrTechniqueImgText {
        width: 100%;
        z-index: 1000;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      }

      &:hover .CurrTechniqueImgText .ImageModalItemBtn {
        display: block;
      }

      &:hover .CurrTechniqueImgText .CurrTechniqueImgDescription .Details {
        display: block;
      }

      .CurrTechniqueImgBackground {
        width: 100%;
        height: 100%;
        background-size: cover;
        filter: brightness(90%);
      }
      .CurrTechniqueImgText {
        position: absolute;
        min-width: 300px;
        min-height: 80px;
        bottom: 0;
        left: 0;
        background-color: rgb(255, 255, 255);
        padding: 10px;

        .CurrTechniqueImgTitle {
          font-family: "Open Sans";
          letter-spacing: -0.5px;
          text-transform: uppercase;
          font-size: 18px;
          margin: 0;
        }

        .CurrTechniqueImgDescription {
          font-family: "Open Sans";
          letter-spacing: -1.5px;
          font-size: 16px;
          margin: 0;
          text-transform: uppercase;
          font-size: 16px;
          color: #c5a47e;

          .Details {
            text-transform: none;
            color: black;
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 1740px) {
  .CurrTechnicCards {
    .CurrTechnicCardsTitle {
      font-size: 70px;
    }
    .CurrTechnicCardsBody {
      .CurrTechniqueImg {
        width: 550px;
        height: 360px;
      }
    }
  }
}
@media (max-width: 1370px) {
  .CurrTechnicCards {
    .CurrTechnicCardsTitle {
      font-size: 60px;
    }
    .CurrTechnicCardsBody {
      .CurrTechniqueImg {
        width: 500px;
        height: 330px;
      }
    }
  }
}
@media (max-width: 1240px) {
  .CurrTechnicCards {
    padding-bottom: 40px;
    .CurrTechnicCardsTitle {
      font-size: 60px;
    }
    .CurrTechnicCardsBody {
      .CurrTechniqueImg {
        width: 450px;
        height: 300px;
      }
    }
  }
}
@media (max-width: 1100px) {
  .CurrTechnicCards {
    padding-bottom: 40px;
    .CurrTechnicCardsTitle {
      font-size: 65px;
    }
    .CurrTechnicCardsBody {
      @apply grid grid-cols-1;
      .CurrTechniqueImg {
        width: 650px;
        height: 400px;
      }
    }
  }
}
@media (max-width: 900px) {
  .CurrTechnicCards {
    padding-bottom: 40px;
    .CurrTechnicCardsTitle {
      font-size: 65px;
    }
    .CurrTechnicCardsBody {
      @apply grid grid-cols-1;
      .CurrTechniqueImg {
        width: 600px;
        height: 360px;
      }
    }
  }
}
@media (max-width: 700px) {
  .CurrTechnicCards {
    padding-bottom: 80px;
    .CurrTechnicCardsTitle {
      font-size: 55px;
    }
    .CurrTechnicCardsBody {
      @apply grid grid-cols-1;
      .CurrTechniqueImg {
        width: 500px;
        height: 300px;
      }
    }
  }
}
@media (max-width: 600px) {
  .CurrTechnicCards {
    padding-bottom: 80px;
    .CurrTechnicCardsTitle {
      font-size: 45px;
    }
    .CurrTechnicCardsBody {
      @apply grid grid-cols-1;
      .CurrTechniqueImg {
        width: 360px;
        height: 240px;
        .CurrTechniqueImgText {
          width: 150px;
          padding: 8px;

          .CurrTechniqueImgTitle {
            font-size: 17px;
          }

          .CurrTechniqueImgDescription {
            font-size: 14px;
          }
        }
      }
    }
  }
}
