@import url("https://fonts.googleapis.com/css2?family=Alumni+Sans:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Khand:wght@300;400;500;600;700&family=PT+Sans+Narrow:wght@400;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto Condensed", sans-serif;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden !important;
  min-width: 365px;
  min-height: 365px;
}

*::-webkit-scrollbar {
  width: 0.45rem;
  background-color: #7b7b7b;
}
*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #d8d8d8;
}
