.ProjectsOur {
  @apply flex justify-center items-center flex-col;
  .ProjectsOurTitle {
    @apply uppercase;
    font-family: "Alumni Sans", sans-serif;
    font-size: 80px;
    font-weight: 500;
    letter-spacing: 4px;
    padding: 10px 0px;
  }
  .ProjectsOurContent1,
  .ProjectsOurContent2 {
    @apply flex justify-end items-center;
    background-color: #2b2b2b;
    width: 100vw;
    display: flex;
    color: white;
    .ProjectsOurContentText {
      padding-left: 50px;
      .ProjectsOurContentTitle {
        width: 550px;
        font-size: 50px;
        font-family: "Alumni Sans";
        font-size: 500;
      }
      .ProjectsOurText {
        width: 420px;
        font-weight: 400;
        font-size: 1.25rem;
        color: #999999;
      }
    }
    .ProjectsOurContentImg {
      img {
        width: 1280px;
        height: auto;
      }
    }
  }
  .ProjectsOurContent2 {
    @apply flex justify-start items-center;
  }
}
@media (max-width: 1740px) {
  .ProjectsOur {
    .ProjectsOurTitle {
      font-size: 70px;
    }
  }
}
@media (max-width: 1500px) {
  .ProjectsOur {
    .ProjectsOurContent1,
    .ProjectsOurContent2 {
      .ProjectsOurContentText {
        padding-left: 30px;
        .ProjectsOurContentTitle {
          width: 500px;
          font-size: 40px;
          font-family: "Alumni Sans";
          font-size: 500;
        }
        .ProjectsOurText {
          width: 420px;
          font-weight: 400;
          font-size: 18px;
          color: #999999;
        }
      }
      .ProjectsOurContentImg {
        img {
          width: 1280px;
          height: auto;
        }
      }
    }
    .ProjectsOurContent2 {
      @apply flex justify-start items-center;
    }
  }
}
@media (max-width: 1300px) {
  .ProjectsOur {
    .ProjectsOurContent1,
    .ProjectsOurContent2 {
      @apply flex justify-end items-center flex-col;
      .ProjectsOurContentText {
        padding-left: 0px;
        padding-bottom: 30px;
        .ProjectsOurContentTitle {
          font-size: 50px;
        }
      }
    }
    .ProjectsOurContent2 {
      @apply flex justify-start items-center flex-col-reverse;
      padding-top: 100px;
    }
  }
}
@media (max-width: 1240px) {
  .ProjectsOur {
    .ProjectsOurTitle {
      font-size: 65px;
    }
  }
}
@media (max-width: 700px) {
  .ProjectsOur {
    .ProjectsOurTitle {
      font-size: 55px;
    }
    .ProjectsOurContent1,
    .ProjectsOurContent2 {
      .ProjectsOurContentText {
        padding: 0px 20px;
        padding-bottom: 30px;
        .ProjectsOurContentTitle {
          font-size: 40px;
          width: auto;
        }
        .ProjectsOurText {
          font-size: 16px;
          width: auto;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .ProjectsOur {
    .ProjectsOurTitle {
      font-size: 45px;
    }
  }
}
