.HomeProjects {
  @apply flex justify-center items-center flex-col;
  .HomeProjectsTitle {
    @apply uppercase;
    font-family: "Alumni Sans", sans-serif;
    font-size: 80px;
    font-weight: 500;
    letter-spacing: 4px;
  }
  .HomeProjectSlider {
    width: 100vw;
    height: 1200px;
    .HomeProjectSlide {
      width: 100vw;
      height: 1200px;
      position: relative;
      background-size: cover;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #2b2b2b;
        background-size: cover;
        opacity: 0.8;
        z-index: 1;
      }
    }
    .HomeProjectSlideData {
      @apply flex flex-col justify-end;
      position: absolute;
      z-index: 100;
      color: white;
      padding-left: 20vw;
      padding-bottom: 200px !important;
      height: calc(100vh - 90px);
      z-index: 1500;
      left: 0;
      bottom: 0;
      .HomeProjectSmallTitle {
        @apply uppercase;
        font-family: "Alumni Sans", sans-serif;
        font-size: 20px;
        font-weight: 500;
        color: #c5a47e;
        letter-spacing: 4px;
      }
      .HomeProjectTitle {
        @apply uppercase;
        font-family: "Alumni Sans", sans-serif;
        font-size: 70px;
        font-weight: 400;
        padding: 0;
      }
      .HomeProjectDescription {
        width: 600px;
      }

      .HomeProjectButton {
        width: 200px;
        height: 60px;
        border: 2px solid white;
        margin-top: 30px;
        transition: 0.3s all;
        border-radius: 2px;

        &:hover {
          scale: 1.05;
        }
        &:active {
          transform: translateY(10px);
        }
      }
    }
  }
  .HomeProjectsNavigation {
    @apply flex flex-col gap-3;
    position: absolute;
    right: 60px;
    padding-top: 200px;
    z-index: 1500;
    .HomeProjectNavButton {
      @apply flex justify-center items-center;
      color: white;
      height: 60px;
      width: 60px;
      border: 1px solid white;
      transition: 0.3s all;

      &:hover {
        scale: 1.05;
      }
      &:active {
        transform: translateY(5px);
      }
    }
  }
}

.ArrowRight {
  fill: white;
}
.ArrowLeft {
  fill: white;
}

@media (max-width: 1240px) {
  .HomeProjects {
    @apply flex justify-center items-center flex-col;
    .HomeProjectsTitle {
      font-size: 65px;
    }
    .HomeProjectSlider {
      height: 1100px;
      .HomeProjectSlide {
        height: 1100px;
      }
    }
  }
}
@media (max-width: 1000px) {
  .HomeProjects {
    .HomeProjectSlider {
      height: 1000px;
      .HomeProjectSlide {
        height: 1000px;
      }
    }
  }
}
@media (max-width: 900px) {
  .HomeProjects {
    @apply flex justify-center items-center flex-col;
    .HomeProjectsTitle {
      font-size: 65px;
    }
    .HomeProjectSlider {
      height: 900px;
      .HomeProjectSlide {
        height: 900px;
      }
      .HomeProjectSlideData {
        padding-left: 10vw;
        padding-bottom: 150px !important;
        .HomeProjectTitle {
          font-size: 65px;
        }
        .HomeProjectDescription {
          width: 500px;
          font-size: 15px;
        }

        .HomeProjectButton {
          width: 180px;
          height: 55px;
        }
      }
    }
  }
}
@media (max-width: 700px) {
  .HomeProjects {
    @apply flex justify-center items-center flex-col;
    .HomeProjectsTitle {
      font-size: 55px;
    }
    .HomeProjectSlider {
      height: 800px;
      .HomeProjectSlide {
        height: 800px;
      }
      .HomeProjectSlideData {
        padding-left: 10vw;
        padding-bottom: 100px !important;
        .HomeProjectTitle {
          font-size: 55px;
        }
        .HomeProjectDescription {
          width: 400px;
          font-size: 14px;
        }
      }
    }
    .HomeProjectsNavigation {
      display: none;
      .HomeProjectNavButton {
        @apply flex justify-center items-center;
        color: white;
        height: 60px;
        width: 60px;
        border: 1px solid white;
        transition: 0.3s all;

        &:hover {
          scale: 1.05;
        }
        &:active {
          transform: translateY(5px);
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .HomeProjects {
    @apply flex justify-center items-center flex-col;
    .HomeProjectsTitle {
      font-size: 45px;
    }
    .HomeProjectSlider {
      height: 750px;
      .HomeProjectSlide {
        height: 750px;
      }
      .HomeProjectSlideData {
        padding-left: 10vw;
        padding-bottom: 80px !important;
        .HomeProjectTitle {
          font-size: 45px;
        }
        .HomeProjectDescription {
          width: 360px;
          font-size: 14px;
        }
      }
    }
    .HomeProjectsNavigation {
      right: 15px;
      .HomeProjectNavButton {
        height: 50px;
        width: 50px;
      }
    }
  }
}
@media (max-width: 500px) {
  .HomeProjects {
    @apply flex justify-center items-center flex-col;
    .HomeProjectsTitle {
      font-size: 45px;
    }
    .HomeProjectSlider {
      height: 700px;
      .HomeProjectSlide {
        height: 700px;
      }
      .HomeProjectSlideData {
        padding-left: 10vw;
        padding-bottom: 80px !important;
        .HomeProjectTitle {
          font-size: 45px;
        }
        .HomeProjectDescription {
          width: 300px;
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
    .HomeProjectsNavigation {
      right: 15px;
      .HomeProjectNavButton {
        height: 50px;
        width: 50px;
      }
    }
  }
}
