.ImageModal {
  @apply flex justify-center items-center;
  position: fixed;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  .ImageModalOverlay {
    position: fixed;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba($color: #111111, $alpha: 0.45);
    backdrop-filter: blur(8px) !important;
  }
  .ImageModalBody {
    border-radius: 2px;
    position: fixed;
    z-index: 10000;
    width: 1400px;
    min-height: 1000px;
    background-color: rgba($color: #ffffff, $alpha: 0.95);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    padding: 20px;
    .ImageHeader {
      @apply flex justify-end items-center;
      width: 100%;
      .ImageHeaderClose {
        @apply flex justify-center items-center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        transition: 0.3s all;

        &:hover {
          background-color: rgb(218, 218, 218);
        }
        &:active {
          transform: translateY(10px);
        }
      }
    }
    .ModalImage {
      margin-top: 20px;
      width: 1400px;
      height: 900px;
      object-fit: cover;
    }
  }
}

.ImageModalItemBtn {
  @apply flex justify-center items-center;
  // font-family: "Alumni Sans", sans-serif;
  font-size: 16px;
  border: 1px solid black;
  top: 85%;
  left: 10px;
  width: 140px;
  height: 35px;
  margin: 10px 0px;
  color: black;
  border-radius: 2px;
  transition: 0.3s all;
  display: none;
  &:hover {
    scale: 1.05;
  }
  &:active {
    transform: translateY(10px);
  }
}

@media (max-width: 1600px) {
  .ImageModal {
    .ImageModalBody {
      width: 1200px;
      min-height: 650px;
      .ModalImage {
        width: 1200px;
        height: auto;
      }
    }
  }
}
@media (max-width: 1300px) {
  .ImageModal {
    .ImageModalBody {
      width: 1000px;
      min-height: 600px;
      .ModalImage {
        width: 1000px;
        height: auto;
      }
    }
  }
}
@media (max-width: 1000px) {
  .ImageModal {
    .ImageModalBody {
      width: 800px;
      min-height: 400px;
      .ModalImage {
        width: 800px;
        height: auto;
      }
    }
  }
}
@media (max-width: 850px) {
  .ImageModal {
    .ImageModalBody {
      width: 600px;
      .ModalImage {
        width: 600px;
      }
    }
  }
}
@media (max-width: 600px) {
  .ImageModal {
    .ImageModalBody {
      width: 400px;
      min-height: 200px;
      .ModalImage {
        width: 400px;
      }
    }
  }
}
@media (max-width: 500px) {
  .ImageModal {
    .ImageModalBody {
      padding: 10px;
      width: 400px;
      .ModalImage {
        width: 400px;
      }
    }
  }
}
@media (max-width: 400px) {
  .ImageModal {
    .ImageModalBody {
      padding: 10px;
      width: 350px;
      .ModalImage {
        margin-top: 5px;
        width: 350px;
      }
    }
  }
}
