.Reasons {
  @apply flex justify-center items-center flex-col;
  min-height: 700px;
  .ReasonsTitle {
    @apply uppercase;
    font-family: "Alumni Sans", sans-serif;
    font-size: 80px;
    font-weight: 500;
    letter-spacing: 4px;
    padding-bottom: 150px;
  }
  .ReasonsCards {
    @apply flex justify-center items-center;
    .ReasonsCard {
      @apply flex  items-center flex-col;
      text-align: center;
      height: 175px;
      .ReasonsCardTitle {
        font-family: "Alumni Sans", sans-serif;
        font-size: 30px;
        font-weight: 500;
        width: 350px;
      }
    }
  }
}
@media (max-width: 1500px) {
  .Reasons {
    min-height: 600px;
  }
}
@media (max-width: 1240px) {
  .Reasons {
    min-height: 500px;
    .ReasonsTitle {
      font-size: 65px;
    }
  }
}
@media (max-width: 1100px) {
  .Reasons .ReasonsCards {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 87px !important;
    margin-bottom: 99px !important;
  }
}
@media (max-width: 831px) {
  .ReasonsCard {
    svg {
      width: 65px;
    }
  }
}
@media (max-width: 823px) {
  .Reasons .ReasonsTitle {
    text-transform: uppercase;
    font-family: "Alumni Sans", sans-serif;
    font-size: 55px;
    font-weight: 500;
    letter-spacing: 2px;
    padding-bottom: 86px;
  }
}

@media (max-width: 600px) {
  .Reasons .ReasonsTitle {
    text-transform: uppercase;
    font-family: "Alumni Sans", sans-serif;
    font-size: 45px !important;
    font-weight: 500;
    letter-spacing: 1px;
    padding-bottom: 66px;
    text-align: center;
  }
}
@media (max-width: 400px) {
  .Reasons {
    .ReasonsTitle {
      width: 365px;
    }
    .ReasonsCards {
      width: 365px;
    }
  }
}
