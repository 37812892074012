.GalleryProjectsTitle {
  @apply uppercase;
  font-family: "Alumni Sans", sans-serif;
  font-size: 80px;
  font-weight: 500;
  letter-spacing: 4px;
  padding: 40px 0px;
}

.GalleryProjectSlider {
  width: 100vw;
  height: 1200px;
  .HomeProjectSlide {
    width: 100vw;
    height: 1200px;
    position: relative;
    background-size: cover;
    background-color: #2b2b2b;
  }
  .HomeProjectSlideData {
    @apply flex flex-col justify-end;
    position: absolute;
    z-index: 100;
    color: white;
    padding-left: 20vw;
    padding-bottom: 200px !important;
    height: calc(100vh - 90px);
    z-index: 1500;
    left: 0;
    bottom: 0;
    .HomeProjectSmallTitle {
      @apply uppercase;
      font-family: "Alumni Sans", sans-serif;
      font-size: 20px;
      font-weight: 500;
      color: #c5a47e;
      letter-spacing: 4px;
    }
    .HomeProjectTitle {
      @apply uppercase;
      font-family: "Alumni Sans", sans-serif;
      font-size: 70px;
      font-weight: 400;
      padding: 0;
    }
    .HomeProjectDescription {
      width: 600px;
    }

    .HomeProjectButton {
      width: 200px;
      height: 60px;
      border: 2px solid white;
      margin-top: 30px;
      transition: 0.3s all;
      border-radius: 2px;

      &:hover {
        scale: 1.05;
      }
      &:active {
        transform: translateY(10px);
      }
    }
  }
}

.HomeProjectVideo {
  height: 100%;
  width: 100vw;
  background-color: #2b2b2b;
  filter: brightness(0.9);
}
.swiper-button-prev,
.swiper-button-next {
  color: white !important;
  margin: 0px 20px !important;
}

@media (max-width: 1600px) {
  .GalleryProjectSlider {
    width: 100vw;
    height: 800px;
    .HomeProjectSlide {
      width: 100vw;
      height: 800px;
    }
  }
}
@media (max-width: 1400px) {
  .GalleryProjectSlider {
    width: 100vw;
    height: 700px;
    .HomeProjectSlide {
      width: 100vw;
      height: 700px;
    }
  }
}
@media (max-width: 1240px) {
  .GalleryProjectsTitle {
    font-size: 65px;
  }
  .GalleryProjectSlider {
    width: 100vw;
    height: 600px;
    .HomeProjectSlide {
      width: 100vw;
      height: 600px;
    }
  }
}
@media (max-width: 700px) {
  .GalleryProjectsTitle {
    font-size: 55px;
  }
  .GalleryProjectSlider {
    width: 100vw;
    height: 500px;
    .HomeProjectSlide {
      width: 100vw;
      height: 500px;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    color: white !important;
    margin: 0px 5px !important;
  }
}
@media (max-width: 600px) {
  .GalleryProjectsTitle {
    font-size: 45px;
  }
  .GalleryProjectSlider {
    width: 100vw;
    height: 400px;
    .HomeProjectSlide {
      width: 100vw;
      height: 400px;
    }
  }
}
@media (max-width: 400px) {
  .GalleryProjectsTitle {
    font-size: 45px;
  }
  .GalleryProjectSlider {
    width: 90vw;
    height: 300px;
    .HomeProjectSlide {
      width: 90vw;
      height: 300px;
    }
  }
}
