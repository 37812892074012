.OurProjects {
  background-color: #2b2b2b;

  .OurProjectsTitle {
    padding-top: 20px;
    margin-top: 5rem;
    text-align: center;
    line-height: 1.1;
    font-weight: 500;
    font-family: Alumni Sans;
    font-size: 80px;
    letter-spacing: 6%;
    color: #ffffff;
    margin-bottom: 40px;
  }

  .OurProjectsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 80px;
    display: flex;

    .OurProjectsItem {
      .OurProjectsItemImg {
        img {
          height: 335px;
          width: 570px;
          object-fit: cover;
        }
      }
      .OurProjectsItemSmallTitle {
        margin-top: 20px;
        color: #c5a47e;
        font-size: 20px;
        font-family: "Alumni Sans";
        font-weight: 500;
        letter-spacing: 5px;
        span {
          color: #999999;
        }
      }
      .OurProjectsItemTitle {
        margin-top: 20px;
        font-family: "Alumni Sans";
        font-weight: 500;
        font-size: 36px;
        color: #ffffff;
      }
    }
  }
  .OurProjectsButtonContainer {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    line-height: 24px;
    letter-spacing: 6%;
    padding-bottom: 40px;
    position: relative;

    .OurProjectsButton {
      font-family: "Alumni Sans";
      font-weight: 500;
      font-size: 22px;
      height: 30px;
      width: 180px;
      color: #c5a47e;
      transition: 0.3s all;
      position: relative;
      z-index: 1;

      ::after {
        content: "";
        width: 180px;
        height: 30px;
        background-color: #ffffff;
        z-index: -1;
        position: absolute;
        left: 0;
        transition: 0.3s all;
      }

      .OurProjectsButtonText {
        @apply flex justify-center items-center;
        position: relative;
        z-index: 2;
        height: 30px;
      }

      &:hover ::after {
        scale: 1.05;
      }
      &:active {
        transform: translateY(10px);
      }
    }
  }
}

@media (max-width: 1740px) {
  .OurProjects {
    .OurProjectsTitle {
      font-size: 70px;
    }
  }
}

@media (max-width: 1240px) {
  .OurProjects {
    .OurProjectsTitle {
      font-size: 65px;
    }
    .OurProjectsContainer {
      flex-direction: column;
      gap: 80px;
      display: flex;
    }
  }
}

@media (max-width: 700px) {
  .OurProjects {
    .OurProjectsTitle {
      font-size: 55px;
    }
    .OurProjectsContainer {
      gap: 60px;

      .OurProjectsItem {
        .OurProjectsItemImg {
          width: 450px;
          height: auto;
        }
        .OurProjectsItemTitle {
          font-size: 30px;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .OurProjects {
    .OurProjectsTitle {
      font-size: 45px;
    }
    .OurProjectsContainer {
      .OurProjectsItem {
        .OurProjectsItemImg {
          width: 400px;
          height: auto;
        }
        .OurProjectsItemTitle {
          font-size: 30px;
        }
      }
    }
  }
}
@media (max-width: 450px) {
  .OurProjects {
    .OurProjectsContainer {
      .OurProjectsItem {
        .OurProjectsItemImg {
          width: 350px;
          height: auto;
        }
        .OurProjectsItemTitle {
          font-size: 26px;
        }
      }
    }
  }
}
